import { useParams } from "react-router-dom";
import PageHeaderUser from "../../../components/Header/Longado";
import CriarProdutos from "../../../components/Produtos/Criar";

function ProdutosCriar() {
  const { uuid } = useParams();
  return (
    <>
      <PageHeaderUser
        ComponenteInterno={<CriarProdutos uuid={uuid ? uuid : ""} />}
        title={`${uuid ? "Editar" : "Criar"} Produtos`}
        description="Lista de Produtos"
      />
    </>
  );
}

export default ProdutosCriar;
