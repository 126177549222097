import { CircularProgress } from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../components/Header/Auth";
import logoMtech from "../../../images/logo-mtech.svg";
import { sendForgotLogin, sendLogin } from "../../../services/Auth";
import { IUserSimplificado } from "../../../utils/interfaces/user";
import {
  FiArrowLeftContent,
  LoginA,
  LoginButton,
  LoginContent,
  LoginContentA,
  LoginContentButton,
  LoginDiv,
  LoginFormularioDiv,
  LoginH3,
  LoginH4,
  LoginH5,
  LoginInput,
  LoginLogoContent,
  LoginTituloDiv,
} from "../stylesAuth";

function Login() {
  const navigate = useNavigate();
  const [user, setUser] = useState<IUserSimplificado>();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [forgot, setForgot] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const login = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    if (await sendLogin(username, password)) {
      const localUser = localStorage.getItem("user");
      if (localUser) {
        setUser(JSON.parse(localUser));
      }
    }
    setIsLoading(false);
  };

  const forgotLogin = (e: FormEvent) => {
    e.preventDefault();
    sendForgotLogin(username);
  };

  const loginForgot = () => {
    setForgot(!forgot);
  };

  useEffect(() => {
    if (user && user.attributes.roles[0]) {
      user.attributes.roles[0] === "ROLE_SUPER_ADMIN"
        ? navigate("/admin/customers", { replace: true })
        : navigate("/admin/brands", { replace: true });
    }
  }, [navigate, user]);

  return (
    <LoginContent>
      <LoginLogoContent>
        <img src={logoMtech} alt="Login" />
      </LoginLogoContent>
      <PageHeader title="Login" />
      <LoginDiv>
        {forgot ? (
          <>
            <LoginTituloDiv>
              <FiArrowLeftContent>
                <FiArrowLeft size={20} onClick={loginForgot} />
              </FiArrowLeftContent>
              <LoginH4>Esqueci minha senha</LoginH4>
            </LoginTituloDiv>
            <LoginFormularioDiv onSubmit={forgotLogin}>
              <LoginH5>
                Insira o seu e-mail cadastrado que enviaremos instruções para
                você recuperar a sua senha
              </LoginH5>
              <br />
              <br />
              <LoginH3>E-mail</LoginH3>
              <LoginInput
                autoComplete="on"
                onChange={(e) => setUsername(e.target.value)}
                title="email"
                type="email"
                name="email"
                id="email"
                defaultValue={username}
              ></LoginInput>
              <br />
              <br />
              <LoginContentButton>
                <LoginButton
                  disabled={username !== "" ? false : true}
                  title="Enviar"
                >
                  {isLoading ? (
                    <CircularProgress size={18} color={"inherit"} />
                  ) : (
                    "Enviar"
                  )}
                </LoginButton>
              </LoginContentButton>
            </LoginFormularioDiv>
          </>
        ) : (
          <>
            <LoginTituloDiv>
              <LoginH4>Preencha os dados abaixo para entrar</LoginH4>
            </LoginTituloDiv>
            <LoginFormularioDiv onSubmit={login}>
              <LoginH3>E-mail</LoginH3>
              <LoginInput
                autoComplete="on"
                onChange={(e) => setUsername(e.target.value)}
                title="email"
                type="email"
                name="email"
                id="email"
              ></LoginInput>
              <br />
              <br />
              <LoginH3>Senha</LoginH3>
              <LoginInput
                autoComplete="on"
                onChange={(e) => setPassword(e.target.value)}
                title="password"
                type="password"
                name="password"
                id="password"
              ></LoginInput>
              <LoginContentA>
                <LoginA onClick={loginForgot}>esqueci minha senha</LoginA>
              </LoginContentA>
              <LoginContentButton>
                <LoginButton
                  disabled={username !== "" && password !== "" ? false : true}
                  title="Entrar"
                >
                  {isLoading ? (
                    <CircularProgress size={18} color={"inherit"} />
                  ) : (
                    "Entrar"
                  )}
                </LoginButton>
              </LoginContentButton>
            </LoginFormularioDiv>
          </>
        )}
      </LoginDiv>
    </LoginContent>
  );
}

export default Login;
