import { styled } from "@mui/material/styles";

export const ClientesDiv = styled("div")`
  color: #1100ff;
`;

export const ColorContainer = styled("div")`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #f6f9fc;
  border: none;
  padding: 0 2px;
  box-shadow: 0px 0px 1px rgb(0 0 0 / 2%), inset 0px 1px 3px rgb(50 50 93 / 15%);
  border-radius: 6px;
`;

export const ColorPicker = styled("input")`
  width: auto !important;
  height: auto !important;
  border: none !important;
  appearance: none !important;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer !important;
  box-shadow: none !important;
  background-color: transparent !important;

  &::-webkit-color-swatch {
    height: 39px;
    width: 39px;
    border: none !important;
    padding: 0 !important;
    border-radius: 6px !important;
  }

  &::-moz-color-swatch {
    height: 39px;
    width: 39px;
    border: none !important;
    padding: 0 !important;
    border-radius: 6px !important;
  }
`;

export const ColorInput = styled("input")`
  width: 100% !important;
  background-color: transparent !important;
  border: none !important;
  padding: none !important;
  box-shadow: none !important;
  border-radius: none !important;
  outline: none !important;
`;

export const LogoInput = styled("input")`
  display: none;
`;

export const LogoSelector = styled("label")`
  color: #0d0d0d;
  font-size: 14px;
  font-weight: 600;
  padding: 13px 40px;
  background-color: #00e693;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 8%), 0px 4px 6px rgb(50 50 93 / 10%);
  border-radius: 6px;
  border: none;
  cursor: pointer;
  text-align: center;
  width: 200px;

  &:hover {
    background-color: #00da8c;
  }
`;

export const LogoPreview = styled("div")`
  width: 200px;
  height: 200px;
  background-color: #f6f6f6;
  margin-bottom: 15px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoContainer = styled("div")`
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    margin-bottom: 36px;
    align-items: center;
  }
`;

export const InfoContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  padding-left: 30px;
  gap: 20px;

  @media (max-width: 800px) {
    gap: 0;
    padding-left: 0px;
  }
`;

export const FormInline = styled("div")`
  display: flex;
  flex-direction: row;
  width: 100%;

  button {
    color: #0d0d0d;
    font-size: 16px;
    font-weight: 600;
    padding: 4px 13px 0 13px;
    background-color: #00e693;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08),
      0px 4px 6px rgba(50, 50, 93, 0.1);
    border-radius: 6px;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #00da8c;
    }
  }

  @media (max-width: 800px) {
    margin-bottom: 0;
    flex-direction: column;
  }
`;

export const UsersSection = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  input {
    margin-bottom: 10px;
  }
`;

export const UsersFilteredList = styled("div")`
  height: 200px;
  width: 100%;
  background-color: #f6f9fc;
  border-radius: 6px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-radius: 6px;
  box-shadow: 0px 0px 1px rgb(0 0 0 / 2%), inset 0px 1px 3px rgb(50 50 93 / 15%);
  overflow: auto;

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
  }
  &::-webkit-scrollbar {
    width: 10px;
  }
`;

export const UsersFilteredListItem = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  color: fieldtext;
  border-bottom: 1px solid #dedede;

  button {
    decoration: none;
    border: none;
    border-radius: 50%;
    padding: 3px 3px 0 3px;
    background-color: #888;
    color: #fff;
    cursor: pointer;

    &.added {
      background-color: green;
      color: #fff;
    }
  }

  &:last-child {
    border: none;
  }
`;

export const UsersFilteredListHeader = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  color: fieldtext;
  background-color: #f6f9fc;
  padding: 20px;

  div {
    cursor: pointer;
  }
`;

export const Users = styled("div")`
  display: flex;
  flex-direction: row;
  margin: 0 75px;
  margin-bottom: 36px;
  padding: 30px;
  background-color: #fcfcfc;
  border-radius: 6px;

  label {
    padding: 15px 0;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 0;
  }
`;

export const UserControl = styled("div")`
  display: flex;
  flex-direction: column;
  flex-basis: fit-content;
  margin-right: 28px;
  height: 100%;

  label {
    font-size: 16px;
    font-weight: 600;
    color: #002554;
  }

  input {
    width: 100%;
    font-size: 16px;
    font-weight: 400;

    background-color: #f6f9fc;
    border: none;
    padding: 14px 12px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.02),
      inset 0px 1px 3px rgba(50, 50, 93, 0.15);
    border-radius: 6px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: inset 1px 1px 2px rgba(50, 50, 93, 0.15) !important;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  @media (max-width: 800px) {
    margin-bottom: 36px;
  }
`;

export const UserTable = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 2;
  align-items: start;
  width: 100%;

  label {
    font-size: 16px;
    font-weight: 600;
    color: #002554;
  }

  table {
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: 400;
    color: #002554;
    box-shadow: 0px 0px 1px rgb(0 0 0 / 2%),
      inset 0px 1px 3px rgb(50 50 93 / 15%);
    border-radius: 6px;
  }

  tbody {
    height: 100%;
    justify-content: start;
    display: flex;
    flex-direction: column;
    overflow: auto;
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 6px;
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
  }

  th {
    text-align: start;
    padding: 15px;
  }

  td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    border-bottom: 1px solid #f2f2f2;

    &:last-child {
      text-align: center;
    }
  }

  tr {
    border-radius: 6px;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #e84343;

    &:hover {
      color: red;
    }
  }
`;
