import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "./tokenSpirado";

export const PrivateRoutes = () => {
  const auth = { token: localStorage.getItem("token") };
  if (auth.token) {
    isAuthenticated(auth.token);
    return <Outlet />;
  }
  return <Navigate to="/login" />;
};

export const TokenRoutes = () => {
  const auth = { token: localStorage.getItem("token") };
  if (auth.token) {
    isAuthenticated(auth.token);
    return <Outlet />;
  }
  return <Navigate to="/admin/users" />;
};

export const Logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("refresh_token");
};
