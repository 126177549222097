import React, { useState, createContext } from "react";
import { Backdrop, Modal } from "../components/styles.global";
import { IUserSimplificado } from "../utils/interfaces/user";
import { MdClose, MdEmail } from "react-icons/md";
import { FaPhone, FaUserCircle } from "react-icons/fa";
import { ICliente } from "../utils/interfaces/clientes";

interface ContextInterface {
  onUserDetails: (user: IUserSimplificado) => void;
  onClientDetails: (user: ICliente) => void;
}

export const ModalContext = createContext<ContextInterface>({
  onUserDetails(user) {},
  onClientDetails(client) {},
});

export default function ModalProvider({ children }: any) {
  const [isBackdropOpen, setIsBackdropOpen] = useState<boolean>(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState<boolean>(false);
  const [isClientModalOpen, setIsClientModalOpen] = useState<boolean>(false);
  const [user, setUser] = useState<IUserSimplificado>();
  const [client, setClient] = useState<ICliente>();

  function onUserDetails(user: IUserSimplificado) {
    setIsClientModalOpen(false);
    setIsBackdropOpen(true);
    setUser(user);
    setIsUserModalOpen(true);
  }

  function onClientDetails(client: ICliente) {
    setIsUserModalOpen(false);
    setIsBackdropOpen(true);
    setClient(client);
    setIsClientModalOpen(true);
  }

  return (
    <ModalContext.Provider value={{ onUserDetails, onClientDetails }}>
      {children}
      <Backdrop className={isBackdropOpen ? "active" : ""}>
        {/* User Modal */}
        <Modal className={isUserModalOpen ? "active" : ""}>
          <header>
            <span>Detalhes de usuário:</span>
            <MdClose
              onClick={() => setIsBackdropOpen(false)}
              className="close"
              size={25}
            />
          </header>
          <div className="ModalBody">
            <div>
              <span>Nome: </span>
              {user?.profiles.full_name}
            </div>{" "}
            <div>
              <span>CPF: </span>
              {user?.profiles.cpf}
            </div>{" "}
            <div>
              <span>Email: </span>
              {user?.attributes.email}
            </div>{" "}
            <div>
              <span>Perfil: </span>
              {user?.attributes.roles[0] === "ROLE_ADMIN"
                ? "Adminstrador"
                : "Super Adminstrador"}
            </div>{" "}
            {user?.profiles.contacts && user.profiles.contacts.length > 0 ? (
              <div>
                <span>Contatos: </span>
                <ul>
                  {user?.profiles.contacts.map((contact) => (
                    <li key={user.uuid}>
                      <span>
                        {contact.type === "email" ? <MdEmail /> : <FaPhone />}
                      </span>{" "}
                      {contact.name}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </Modal>
        {/* Customer Modal */}
        <Modal className={isClientModalOpen ? "active" : ""}>
          <header>
            <span>Detalhes de cliente:</span>
            <MdClose
              onClick={() => setIsBackdropOpen(false)}
              className="close"
              size={25}
            />
          </header>
          <div className="ModalBody">
            <div>
              <span>Nome: </span>
              {client?.name}
            </div>{" "}
            <div>
              <span>Nome de exibição: </span>
              {client?.corporateName}
            </div>{" "}
            <div>
              <span>CNPJ: </span>
              {client?.cnpj}
            </div>{" "}
            <div>
              <span>Cor principal: </span>
              <div
                className="sample"
                style={{ backgroundColor: client?.firstBackgroundColor }}
              ></div>
            </div>{" "}
            <div>
              <span>Cor secundária: </span>{" "}
              <div
                className="sample"
                style={{ backgroundColor: client?.secondBackgroundColor }}
              ></div>
            </div>{" "}
            <div>
              <span>Cor da fonte: </span>{" "}
              <div
                className="sample"
                style={{ backgroundColor: client?.fontColor }}
              ></div>
            </div>{" "}
            {client?.users && client.users.length > 0 ? (
              <div>
                <span>Usuários: </span>
                <ul>
                  {client?.users.map((user) => (
                    <li key={user.uuid}>
                      <span>
                        <FaUserCircle />
                      </span>{" "}
                      <div>{user.profiles.full_name}</div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </Modal>
      </Backdrop>
    </ModalContext.Provider>
  );
}
