import React from "react";

import { PageHeaderDiv } from "./styles";

interface PageHeaderProps {
  title: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
  return <PageHeaderDiv>{(document.title = title)}</PageHeaderDiv>;
};

export default PageHeader;
