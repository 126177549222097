import { useParams } from "react-router-dom";
import PageHeaderUser from "../../../components/Header/Longado";
import CriarUser from "../../../components/User/Criar";

function UserCriar() {
  const { uuid } = useParams();

  return (
    <>
      <PageHeaderUser
        ComponenteInterno={<CriarUser uuid={uuid ? uuid : "novo"} />}
        title="Usuários"
        description="Lista de Usuários"
      />
    </>
  );
}

export default UserCriar;
