import { styled } from "@mui/material/styles";
import { FiEdit, FiTrash2, FiSearch } from "react-icons/fi";

export const ContainerAll = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const TituloContainerAll = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  align-items: center;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  color: #525f7f;
  border-bottom: 1px solid #e9ecef;
`;

export const AllTableList = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: justify;
  }
  th {
    background-color: #f6f9fc;
    height: 40px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #002554;
    padding-left: 20px;
    border-bottom: 1px solid #e9ecef;
  }
  td {
    height: 65px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #525f7f;
    padding-left: 20px;
    border-bottom: 1px solid #e9ecef;
  }
  tr {
  }
`;

export const IconFiEdit = styled(FiEdit)`
  margin-right: 10px;
  :hover {
    color: #00e693;
    cursor: pointer;
  }
`;

export const IconFiTrash2 = styled(FiTrash2)`
  margin-right: 10px;
  :hover {
    color: #00e693;
    cursor: pointer;
  }
`;

export const IconFiSearch = styled(FiSearch)`
  margin-right: 10px;
  :hover {
    color: #00e693;
    cursor: pointer;
  }
`;

export const IconSpace = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

export const Backdrop = styled("div")`
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  z-index: 99999;

  &.active {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    transition: "ease 0.4s";
    justify-content: center;
    align-items: center;
  }
`;

export const Modal = styled("div")`
  width: 100vw;
  max-width: 900px;
  height: auto;
  max-height: 90vh;
  background-color: gray;
  background-color: #f4f4f4;
  border-radius: 6px;
  margin: 20px;
  display: none;
  flex-direction: column;

  &.active {
    display: flex;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 30px;

    span {
      color: #656565;
      font-size: 20px;
    }

    .close {
      cursor: pointer;
      color: #656565;
      &:hover {
        color: #00e693;
        border-radius: 6px;
      }
    }
  }

  .ModalBody {
    padding: 30px;
    background-color: #f4f4f4;
    border-radius: 6px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    div {
      span {
        font-weight: bold;
      }

      .sample {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }

      ul {
        padding: 15px;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 10px;

        li {
          display: flex;
          align-items: center;

          span {
            margin-right: 10px;
          }
      }
    }
  }
`;
