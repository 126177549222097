import React, { FormEvent, useEffect, useState } from "react";
import { FaPhone } from "react-icons/fa";
import { IoTrash } from "react-icons/io5";
import { MdEmail, MdOutlineAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { userGetUUID, userPost, userPut } from "../../../services/Users";
import { IContactType, IPostUser } from "../../../utils/interfaces/user";
import {
  emailRegex,
  cpfMask,
  phoneMask,
  phoneRegex,
} from "../../../utils/tools/mascaras";
import {
  ButtonContainer,
  Column,
  DivTable,
  FormControl,
  FormInline,
  Row,
  SelectContainer,
  TdData,
  UserDiv,
  UserDivTitle,
  UserInput,
  UserSubmit,
} from "./styles";

interface IProps {
  uuid: string;
}

const rolesOptions = [
  {
    value: "ROLE_ADMIN",
    label: "Administrador",
  },
  {
    value: "ROLE_SUPER_ADMIN",
    label: "Super Administrador",
  },
];

const contactTypes = [
  {
    value: "mobile_number",
    label: "Telefone/Celular",
  },
  {
    value: "email",
    label: "Email",
  },
];

const PageUser: React.FC<IProps> = ({ uuid }) => {
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [cpf, setCpf] = useState<string>("");
  const [roles, setRoles] = useState<string[]>([]);
  const [contacts, setContacts] = useState<IContactType[]>([]);

  const [contactType, setContactType] = useState<string>(contactTypes[0].value);
  const [contactValue, setContactValue] = useState<string>("");

  useEffect(() => {
    if (uuid !== "new") {
      userGetUUID(uuid)
        .then((res) => {
          if (res !== false) {
            setName(res.data.data.profiles.full_name);
            setEmail(res.data.data.attributes.email);
            setCpf(res.data.data.profiles.cpf);
            setRoles(res.data.data.attributes.roles);
            setContacts(res.data.data.profiles.contacts);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setName("");
      setEmail("");
      setCpf("");
      setRoles([rolesOptions[0].value]);
      setContacts([]);
      setContactType(contactTypes[0].value);
    }
  }, [uuid]);

  const createUser = (UserData: IPostUser) => {
    userPost(UserData)
      .then((result: any) => {
        navigate(`/admin/users/${result.data.data.uuid}`, { replace: true });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateUser = (UserData: IPostUser) => {
    userPut(UserData, uuid)
      .then((result: any) => {
        navigate(`/admin/users/${result.data.data.uuid}`, {
          replace: true,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const cpfRegex = /\d{11}/g;

    if (name.length < 3) {
      toast.warning("Favor informar um nome válido");
      return;
    }

    if (!cpfRegex.test(cpf)) {
      toast.warning("CPF incorreto");
      return;
    }

    const UserData = {
      email,
      full_name: name,
      cpf,
      roles,
      contacts,
    };

    uuid === "new" ? createUser(UserData) : updateUser(UserData);
  };

  const handleSubmitAddContact = () => {
    if (contactType === "email" && !emailRegex.test(contactValue)) {
      toast.warning("Email inválido");
      return;
    }

    if (contactType === "mobile_number" && !phoneRegex.test(contactValue)) {
      toast.warning("Telefone/Celular inválido");
      return;
    }

    setContacts([...contacts, { name: contactValue, type: contactType }]);
    setContactType(contactTypes[0].value);
    setContactValue("");
  };

  const handleDeleteContact = (index: number) => {
    let contactsData = { ...contacts };
    contactsData = contacts ? contacts.filter((contact, i) => i !== index) : [];
    setContacts(contactsData);
  };

  return (
    <UserDiv onSubmit={handleSubmit}>
      <UserDivTitle>
        {uuid === "new" ? "Adicionar novo usuário" : "Editar usuário"}
      </UserDivTitle>
      <Row>
        <FormControl>
          <label htmlFor="name">Nome</label>
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>

        <FormControl>
          <label htmlFor="email">Email</label>
          <input
            type="text"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
      </Row>

      <Row>
        <FormControl>
          <label htmlFor="cpf">CPF</label>
          <input
            type="text"
            name="cpf"
            id="cpf"
            readOnly={uuid === "new" ? false : true}
            value={cpfMask(cpf)}
            onChange={(e) => setCpf(e.target.value.replace(/\D/g, ""))}
          />
        </FormControl>

        <FormControl>
          <label htmlFor="perfil">Perfil</label>
          <SelectContainer className="no-margin">
            <select
              value={roles[0]}
              onChange={(e) => setRoles([e.target.value])}
            >
              {rolesOptions.map((role) => (
                <option key={role.value} value={role.value}>
                  {role.label}
                </option>
              ))}
            </select>
          </SelectContainer>
        </FormControl>
      </Row>

      <Column>
        <FormControl>
          <label htmlFor="contatos">Inserir contato</label>
          <FormInline>
            <SelectContainer>
              <select
                className="contact-type"
                value={contactType}
                onChange={(e) => setContactType(e.target.value)}
              >
                {contactTypes.map((contactType) => (
                  <option key={contactType.value} value={contactType.value}>
                    {contactType.label}
                  </option>
                ))}
              </select>
            </SelectContainer>
            <UserInput
              type="text"
              name="contactValue"
              id="contactValue"
              value={
                contactType === "email" ? contactValue : phoneMask(contactValue)
              }
              onChange={(e) =>
                setContactValue(
                  contactType === "email"
                    ? e.target.value
                    : e.target.value.replace(/\D/g, "")
                )
              }
              maxLength={contactType === "email" ? undefined : 15}
              placeholder={
                contactType === "email"
                  ? "Digite um email para contato"
                  : "Digite um telefone para contato"
              }
            />
            <UserSubmit type="button" onClick={handleSubmitAddContact}>
              <MdOutlineAdd />
            </UserSubmit>
          </FormInline>
        </FormControl>

        {contacts && contacts.length > 0 && (
          <DivTable>
            <table>
              <tbody>
                <tr>
                  <th>Contatos</th>
                </tr>
                {contacts.map((contact, i) => (
                  <tr key={contact.uuid ?? contact.name}>
                    <td>
                      <TdData>
                        <span>
                          {contact.type === "email" ? <MdEmail /> : <FaPhone />}
                        </span>
                        {contact.type === "email"
                          ? contact.name
                          : phoneMask(contact.name)}
                      </TdData>
                      <button
                        type="button"
                        onClick={(e) => handleDeleteContact(i)}
                      >
                        <IoTrash size={20} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </DivTable>
        )}
      </Column>

      <ButtonContainer>
        <button>
          {uuid === "new" ? "Adicionar usuário" : "Editar usuário"}
        </button>
      </ButtonContainer>
    </UserDiv>
  );
};

export default PageUser;
