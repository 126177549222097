import jwt_decode from "jwt-decode";
import { sendLoginRefreshPos } from "../../services/Auth";
import { jwtToken } from "../../utils/interfaces/jwtToken";

export const isAuthenticated = async (token: string) => {
  if (token) {
    const decoded = jwt_decode(token) as jwtToken;
    if (decoded.exp < Date.now() / 1000) {
      await sendLoginRefreshPos();
    }
  }
};
