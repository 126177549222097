import React, { ReactElement, useEffect, useState } from "react";
import { AiOutlineMenu, AiOutlineUnorderedList } from "react-icons/ai";
import {
  FiBookmark,
  FiBriefcase,
  FiPlus,
  FiShoppingBag,
  FiUser,
  FiUsers,
} from "react-icons/fi";
import { TfiClose } from "react-icons/tfi";
import { MdOutlineArrowBack, MdOutlineArrowForward } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

import logoMtechBlack from "../../../images/logo-mtech-black.svg";
import logoMtechExpanded from "../../../images/logomark-mtech.png";
import { IUserSimplificado } from "../../../utils/interfaces/user";
import { Logout } from "../../Auth/isAuthenticated";
import {
  AlignCenter,
  Backdrop,
  BsFillPlayFillStyled,
  CloseSidebar,
  ComponentContent,
  CopyrightContainer,
  DetalhesUser,
  ExpandedButton,
  Header,
  HeaderActions,
  HeaderComponent,
  HeaderContent,
  HeaderSearch,
  IconFiLogOut,
  LogoMenu,
  MenuButton,
  MenuContainer,
  MenuContainerHeader,
  MenuContainerItem,
  MenuContainerSection,
  PageHeaderButtonDiv,
  Sidebar,
  SidebarHeaderContainer,
  SidebarMenu,
  UserAvatar,
  UserSpan,
} from "./styles";
import { MdAdd } from "react-icons/md";

interface PageHeaderProps {
  title: string;
  description?: string;
  ComponenteInterno: ReactElement<any, any>;
  ButtonLink?: string;
}

const PageHeaderUser: React.FC<PageHeaderProps> = ({
  title,
  description,
  ComponenteInterno,
  ButtonLink,
}) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [user, setUser] = useState<IUserSimplificado>();
  const navigate = useNavigate();
  const LogOut = () => {
    Logout();
    return navigate("/login", { replace: true });
  };

  useEffect(() => {
    const localUser = localStorage.getItem("user");
    if (localUser) {
      setUser(JSON.parse(localUser));
    }
  }, []);

  useEffect(() => {
    if (open) {
      setExpanded(true);
    }
  }, [open]);

  const Rotas = (rota: string) => {
    return navigate(rota, { replace: true });
  };

  function capitalizedName(name: string | undefined) {
    name = name ? name.toLowerCase() : "";
    let words = name.split(" ");
    words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return words.toString().replaceAll(",", " ");
  }

  return (
    <SidebarHeaderContainer>
      <Backdrop
        className={open ? "opened" : ""}
        onClick={() => setOpen(!open)}
      />
      <Sidebar
        style={{
          minWidth: expanded ? "" : "90px",
          transition: "ease 0.4s",
        }}
        className={open ? "opened" : ""}
      >
        <SidebarMenu>
          {!open && (
            <ExpandedButton onClick={() => setExpanded(!expanded)}>
              {expanded ? <MdOutlineArrowBack /> : <MdOutlineArrowForward />}
            </ExpandedButton>
          )}
          <CloseSidebar onClick={() => setOpen(!open)}>
            <TfiClose />
          </CloseSidebar>
          <AlignCenter>
            <LogoMenu
              src={expanded ? logoMtechBlack : logoMtechExpanded}
              alt="CM2Tech"
            />
          </AlignCenter>
          <MenuContainer>
            <MenuContainerSection
              style={{ alignItems: expanded ? "" : "center" }}
            >
              <MenuContainerHeader>
                <FiBookmark size={24} />
                {expanded && <span>Marcas</span>}
              </MenuContainerHeader>
              <MenuContainerItem
                style={{ marginLeft: !expanded ? "0" : "" }}
                onClick={() => {
                  Rotas("/admin/brands");
                }}
                className={
                  location.pathname === "/admin/brands" ? "active" : ""
                }
              >
                {!expanded && <AiOutlineUnorderedList />}
                {expanded && <BsFillPlayFillStyled size={12} />}
                {expanded && <span>Todos as marcas</span>}
              </MenuContainerItem>
              <MenuContainerItem
                style={{ marginLeft: !expanded ? "0" : "" }}
                onClick={() => {
                  Rotas("/admin/brands/new");
                }}
                className={
                  location.pathname === "/admin/brands/new" ? "active" : ""
                }
              >
                {!expanded && <MdAdd />}
                {expanded && <BsFillPlayFillStyled size={12} />}
                {expanded && <span>Adicionar nova</span>}
              </MenuContainerItem>
            </MenuContainerSection>

            <MenuContainerSection
              style={{ alignItems: expanded ? "" : "center" }}
            >
              <MenuContainerHeader>
                <FiShoppingBag size={24} />
                {expanded && <span>Produtos</span>}
              </MenuContainerHeader>
              <MenuContainerItem
                style={{ marginLeft: !expanded ? "0" : "" }}
                onClick={() => {
                  Rotas("/admin/products");
                }}
                className={
                  location.pathname === "/admin/products" ? "active" : ""
                }
              >
                {!expanded && <AiOutlineUnorderedList />}
                {expanded && <BsFillPlayFillStyled size={12} />}
                {expanded && <span>Todos os produtos</span>}
              </MenuContainerItem>
              <MenuContainerItem
                style={{ marginLeft: !expanded ? "0" : "" }}
                onClick={() => {
                  Rotas("/admin/products/new");
                }}
                className={
                  location.pathname === "/admin/products/new" ? "active" : ""
                }
              >
                {!expanded && <MdAdd />}
                {expanded && <BsFillPlayFillStyled size={12} />}
                {expanded && <span>Adicionar novo</span>}
              </MenuContainerItem>
            </MenuContainerSection>

            <MenuContainerSection
              style={{ alignItems: expanded ? "" : "center" }}
            >
              <MenuContainerHeader>
                <FiUsers size={24} />
                {expanded && <span>Usuários</span>}
              </MenuContainerHeader>
              <MenuContainerItem
                style={{ marginLeft: !expanded ? "0" : "" }}
                onClick={() => {
                  Rotas("/admin/users");
                }}
                className={location.pathname === "/admin/users" ? "active" : ""}
              >
                {!expanded && <AiOutlineUnorderedList />}
                {expanded && <BsFillPlayFillStyled size={12} />}
                {expanded && <span>Todos os usuários</span>}
              </MenuContainerItem>
              <MenuContainerItem
                style={{ marginLeft: !expanded ? "0" : "" }}
                onClick={() => {
                  Rotas("/admin/users/new");
                }}
                className={
                  location.pathname === "/admin/users/new" ? "active" : ""
                }
              >
                {!expanded && <MdAdd />}
                {expanded && <BsFillPlayFillStyled size={12} />}
                {expanded && <span>Adicionar novo</span>}
              </MenuContainerItem>
            </MenuContainerSection>
            {user?.attributes.roles.includes("ROLE_SUPER_ADMIN") && (
              <MenuContainerSection
                style={{ alignItems: expanded ? "" : "center" }}
              >
                <MenuContainerHeader>
                  <FiBriefcase size={24} />
                  {expanded && <span>Clientes</span>}
                </MenuContainerHeader>
                <MenuContainerItem
                  style={{ marginLeft: !expanded ? "0" : "" }}
                  onClick={() => {
                    Rotas("/admin/customers");
                  }}
                  className={
                    location.pathname === "/admin/customers" ? "active" : ""
                  }
                >
                  {!expanded && <AiOutlineUnorderedList />}
                  {expanded && <BsFillPlayFillStyled size={12} />}
                  {expanded && <span>Todos os clientes</span>}
                </MenuContainerItem>
                <MenuContainerItem
                  style={{ marginLeft: !expanded ? "0" : "" }}
                  onClick={() => {
                    Rotas("/admin/customers/new");
                  }}
                  className={
                    location.pathname === "/admin/customers/new" ? "active" : ""
                  }
                >
                  {!expanded && <MdAdd />}
                  {expanded && <BsFillPlayFillStyled size={12} />}
                  {expanded && <span>Adicionar novo</span>}
                </MenuContainerItem>
              </MenuContainerSection>
            )}
          </MenuContainer>
        </SidebarMenu>
        {expanded && (
          <CopyrightContainer>
            <span>Desenvolvido por</span>{" "}
            <a href="https://maactech.io/" target="_blank" rel="noreferrer">
              <b>MAAC–TECH</b>
            </a>
          </CopyrightContainer>
        )}
      </Sidebar>
      <HeaderContent>
        <Header>
          <HeaderActions>
            <MenuButton onClick={() => setOpen(!open)}>
              <AiOutlineMenu color="#ffffff" size={20} />
            </MenuButton>
            <HeaderSearch
              type="search"
              id="site-search"
              name="q"
              placeholder="Buscar..."
            />
          </HeaderActions>
          <DetalhesUser>
            <UserSpan>{capitalizedName(user?.profiles.full_name)}</UserSpan>
            <UserAvatar>
              <FiUser />
            </UserAvatar>
            <IconFiLogOut onClick={LogOut} />
          </DetalhesUser>
        </Header>
        <HeaderComponent>
          <h1>{title}</h1>
          {ButtonLink ? (
            <PageHeaderButtonDiv
              onClick={() => {
                Rotas(ButtonLink);
              }}
            >
              Adicionar novo <FiPlus />
            </PageHeaderButtonDiv>
          ) : null}
        </HeaderComponent>
        <ComponentContent>{ComponenteInterno}</ComponentContent>
      </HeaderContent>
    </SidebarHeaderContainer>
  );
};

export default PageHeaderUser;
