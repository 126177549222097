import React from "react";

import { MarcasDiv } from "./styles";

const PageMarcas: React.FC<any> = () => {
  return (
    <MarcasDiv>
      <div>Pagina Criar Marcas</div>
    </MarcasDiv>
  );
};

export default PageMarcas;
