import React, { FormEvent, useEffect, useState } from "react";
import {
  clienteGetUUID,
  customerPost,
  customerPut,
} from "../../../services/Customer";
import { IUserSimplificado } from "../../../utils/interfaces/user";
import { cnpjMask } from "../../../utils/tools/mascaras";
import {
  ButtonContainer,
  FormControl,
  Row,
  TdData,
  UserDiv,
  UserDivTitle,
} from "../../User/Criar/styles";

import {
  ColorContainer,
  ColorInput,
  ColorPicker,
  FormInline,
  InfoContainer,
  LogoContainer,
  LogoInput,
  LogoPreview,
  LogoSelector,
  UserControl,
  Users,
  UserTable,
} from "./styles";

import placeholder from "../../../images/placeholder-image.png";
import { IoTrash } from "react-icons/io5";
import UserFilteredInput from "./UserFilteredInput";
import { toast } from "react-toastify";
import { IClientePost } from "../../../utils/interfaces/clientes";
import { useNavigate } from "react-router-dom";

interface IProps {
  uuid: string;
}

const PageClientes: React.FC<IProps> = ({ uuid }) => {
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [cnpj, setCnpj] = useState<string>("");
  const [corporateName, setCorporateName] = useState<string>("");
  const [logo, setLogo] = useState<File>();
  const [firstBackgroundColor, setFirstBackgroundColor] = useState<string>("");
  const [secondBackgroundColor, setSecondBackgroundColor] =
    useState<string>("");
  const [fontColor, setFontColor] = useState<string>("");
  const [users, setUsers] = useState<IUserSimplificado[]>([]);
  const [preview, setPreview] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (uuid !== "new") {
      clienteGetUUID(uuid)
        .then((res) => {
          if (res !== false) {
            setName(res.data.data.name);
            setCnpj(res.data.data.cnpj);
            setCorporateName(res.data.data.corporateName);
            setFirstBackgroundColor(res.data.data.firstBackgroundColor);
            setSecondBackgroundColor(res.data.data.secondBackgroundColor);
            setUsers(res.data.data.users);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setName("");
      setCnpj("");
      setCorporateName("");
      setFirstBackgroundColor("");
      setSecondBackgroundColor("");
      setUsers([]);
    }
  }, [uuid]);

  useEffect(() => {
    if (logo) {
      setPreview(URL.createObjectURL(logo));
    }
  }, [logo]);

  const createCustomer = (CustomerData: IClientePost) => {
    customerPost(CustomerData)
      .then((result: any) => {
        navigate(`/admin/customers/${result.data.data.uuid}`, {
          replace: true,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateCustomer = (CustomerData: IClientePost) => {
    customerPut(CustomerData, uuid)
      .then((result: any) => {
        navigate(`/admin/customers/${result.data.data.uuid}`, {
          replace: true,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const cnpjRegex = /\d{14}/g;

    if (name.length < 3) {
      toast.warning("Favor informar um nome válido");
      return;
    }

    if (corporateName.length < 3) {
      toast.warning("Favor informar um nome de exibição válido");
      return;
    }

    if (!cnpjRegex.test(cnpj)) {
      toast.warning("CNPJ incorreto");
      return;
    }

    const CustomerData = {
      name,
      corporateName,
      cnpj,
      firstBackgroundColor,
      secondBackgroundColor,
      users: users.map((user) => user.uuid),
      fontColor,
      type: "text",
      uuid: uuid === "new" ? "" : uuid,
    };

    uuid === "new"
      ? createCustomer(CustomerData)
      : updateCustomer(CustomerData);
  };

  const handleDeleteUser = (user: IUserSimplificado) => {
    let usersData = { ...users };
    usersData = users ? users.filter((u) => u.uuid !== user.uuid) : [];
    setUsers(usersData);
  };

  const addSelectedUsers = (user: IUserSimplificado) => {
    const userAlready = users.filter((u) => u.uuid === user.uuid);
    if (userAlready.length > 0) {
      toast.warning("Usuário já adicionado");
      return;
    }

    setUsers([...users, user]);
  };

  return (
    <UserDiv onSubmit={handleSubmit}>
      <UserDivTitle>
        {uuid === "new" ? "Adicionar novo cliente" : "Editar cliente"}
      </UserDivTitle>
      <Row>
        <LogoContainer>
          <LogoPreview>
            <img src={preview ?? placeholder} alt={logo?.name} height="100%" />
          </LogoPreview>
          <LogoSelector htmlFor="logo-select">
            <span>Carregar imagem</span>
          </LogoSelector>
          <LogoInput
            type="file"
            name="logo"
            id="logo-select"
            accept="image/*"
            onChange={(e) => setLogo(e.target.files?.[0])}
          />
        </LogoContainer>
        <InfoContainer>
          <FormControl>
            <label htmlFor="name">Razão Social</label>
            <input
              type="text"
              name="name"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormInline>
            <FormControl>
              <label htmlFor="cnpj">CNPJ</label>
              <input
                type="text"
                name="cnpj"
                id="cnpj"
                value={cnpjMask(cnpj)}
                onChange={(e) => setCnpj(e.target.value.replace(/\D/g, ""))}
              />
            </FormControl>

            <FormControl>
              <label htmlFor="corporateName">Nome de Exibição</label>
              <input
                type="text"
                name="corporateName"
                id="corporateName"
                value={corporateName}
                onChange={(e) => setCorporateName(e.target.value)}
              />
            </FormControl>
          </FormInline>
          <FormInline>
            <FormControl>
              <label htmlFor="firstBackgroundColor">Cor Primária</label>
              <FormInline>
                <ColorContainer>
                  <ColorPicker
                    type="color"
                    name="firstBackgroundColor"
                    id="firstBackgroundColor"
                    value={firstBackgroundColor}
                    onChange={(e) => setFirstBackgroundColor(e.target.value)}
                  />
                  <ColorInput
                    type="text"
                    name="firstBackgroundColor"
                    id="firstBackgroundColor"
                    value={firstBackgroundColor}
                    onChange={(e) => setFirstBackgroundColor(e.target.value)}
                  />
                </ColorContainer>
              </FormInline>
            </FormControl>

            <FormControl>
              <label htmlFor="secondBackgroundColor">Cor Secundária</label>
              <FormInline>
                <ColorContainer>
                  <ColorPicker
                    type="color"
                    name="secondBackgroundColor"
                    id="secondBackgroundColor"
                    value={secondBackgroundColor}
                    onChange={(e) => setSecondBackgroundColor(e.target.value)}
                  />
                  <ColorInput
                    type="text"
                    name="secondBackgroundColor"
                    id="secondBackgroundColor"
                    value={secondBackgroundColor}
                    onChange={(e) => setSecondBackgroundColor(e.target.value)}
                  />
                </ColorContainer>
              </FormInline>
            </FormControl>

            <FormControl>
              <label htmlFor="fontColor">Cor da Fonte</label>
              <FormInline>
                <ColorContainer>
                  <ColorPicker
                    type="color"
                    name="fontColor"
                    id="fontColor"
                    value={fontColor}
                    onChange={(e) => setFontColor(e.target.value)}
                  />
                  <ColorInput
                    type="text"
                    name="fontColor"
                    id="fontColor"
                    value={fontColor}
                    onChange={(e) => setFontColor(e.target.value)}
                  />
                </ColorContainer>
              </FormInline>
            </FormControl>
          </FormInline>
        </InfoContainer>
      </Row>

      <Users>
        <UserControl>
          <label htmlFor="users">Inserir usuário</label>
          <UserFilteredInput
            setSelectedUsers={addSelectedUsers}
            selectedUsers={users}
          />
        </UserControl>

        <UserTable>
          <label htmlFor="users">Usuários relacionados</label>
          <table>
            <tbody>
              {users.length > 0 ? (
                users.map((user) => (
                  <tr key={user.uuid ?? user.profiles.full_name}>
                    <td>
                      <TdData>{user.profiles.full_name}</TdData>
                      <button
                        type="button"
                        onClick={(e) => handleDeleteUser(user)}
                      >
                        <IoTrash size={20} />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr style={{ background: "none" }}>
                  <td>
                    <i>Não existem usuários relacionados</i>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </UserTable>
      </Users>

      <ButtonContainer>
        <button>
          {uuid === "new" ? "Adicionar cliente" : "Editar cliente"}
        </button>
      </ButtonContainer>
    </UserDiv>
  );
};

export default PageClientes;
