import { toast } from "react-toastify";
import {
  ClienteListResponse,
  clienteListResponseNoPagination,
  IClientePost,
} from "../utils/interfaces/clientes";
import api from "./api";

// lista de Cliente paginada
export const clientesGet = async (pageSize: number, pageNumber: number) => {
  try {
    return (await toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(
            `/admin/customers?&sort=-createdAt&page[size]=${pageSize}&page[number]=${pageNumber}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response: ClienteListResponse) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
      {
        pending: "Buscando Clientes...",
        error: "Erro ao listar Clientes",
      }
    )) as ClienteListResponse;
  } catch (erro) {
    return false;
  }
};

// criação de cliente
export const customerPost = async (Customer: IClientePost) => {
  try {
    const {
      name,
      corporateName,
      cnpj,
      firstBackgroundColor,
      secondBackgroundColor,
      users,
      fontColor,
    } = Customer;

    return await toast.promise(
      new Promise((resolve, reject) => {
        api
          .post(
            "/admin/customers",
            {
              name,
              corporateName,
              cnpj,
              firstBackgroundColor,
              secondBackgroundColor,
              users,
              fontColor,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response: clienteListResponseNoPagination) => {
            return resolve(response);
          })
          .catch((error: clienteListResponseNoPagination) => {
            reject(error);
          });
      }),
      {
        pending: "Criando cliente...",
        success: "Cliente criado",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as {
              response: clienteListResponseNoPagination;
            };

            return data.response.data.errors?.map((error) => error.detail);
          },
        },
      }
    );
  } catch (erro) {
    console.error(erro);
    return false;
  }
};

// Buscar usuário uuid
export const clienteGetUUID = async (uuid: string) => {
  try {
    return (await toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(`/admin/customers/${uuid}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
      {
        pending: "Buscando cliente...",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as {
              response: clienteListResponseNoPagination;
            };
            return data.response.data.errors?.map((error) => error.detail);
          },
        },
      }
    )) as clienteListResponseNoPagination;
  } catch (erro) {
    console.error(erro);
    return false;
  }
};

// atualizar de cliente
export const customerPut = async (Customer: IClientePost, uuid: string) => {
  try {
    const {
      name,
      corporateName,
      cnpj,
      firstBackgroundColor,
      secondBackgroundColor,
      users,
      fontColor,
    } = Customer;

    return await toast.promise(
      new Promise((resolve, reject) => {
        api
          .put(
            `/admin/customers/${uuid}`,
            {
              name,
              corporateName,
              cnpj,
              firstBackgroundColor,
              secondBackgroundColor,
              users,
              fontColor,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response: clienteListResponseNoPagination) => {
            return resolve(response);
          })
          .catch((error: clienteListResponseNoPagination) => {
            reject(error);
          });
      }),
      {
        pending: "Editando cliente...",
        success: "Cliente editado",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as {
              response: clienteListResponseNoPagination;
            };

            return data.response.data.errors?.map((error) => error.detail);
          },
        },
      }
    );
  } catch (erro) {
    console.error(erro);
    return false;
  }
};

// delete de Cliente
export const clienteDelete = async (uuid: string) => {
  try {
    return await toast.promise(
      new Promise((resolve, reject) => {
        api
          .delete(`/admin/customers/${uuid}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
      {
        pending: "Deletando cliente...",
        success: "Cliente deletado",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as {
              response: clienteListResponseNoPagination;
            };
            return data.response.data.errors?.map((error) => error.detail);
          },
        },
      }
    );
  } catch (erro) {
    console.error(erro);
    return false;
  }
};
