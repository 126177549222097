import { toast } from "react-toastify";
import {
  IPostUser,
  userListResponse,
  userListResponseNoPagination,
} from "../utils/interfaces/user";
import api from "./api";

// dados de único usuário
export const dadosUser = async (token: string) => {
  try {
    const dados = (await api.get(`/admin/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })) as userListResponseNoPagination;
    localStorage.setItem("user", JSON.stringify(dados.data.data));
  } catch (erro) {
    toast.error("Token Invalido");
  }
};

// lista de usuários paginada
export const userGet = async (pageSize: number, pageNumber: number) => {
  try {
    return (await toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(
            `/admin/users?&sort=-createdAt&page[size]=${pageSize}&page[number]=${pageNumber}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response: userListResponse) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
      {
        pending: "Buscando Usuários...",
        error: "Erro ao listar Usuários",
      }
    )) as userListResponse;
  } catch (erro) {
    return false;
  }
};

// criação de usuário
export const userPost = async (User: IPostUser) => {
  try {
    const { email, full_name, roles, contacts, cpf } = User;

    return await toast.promise(
      new Promise((resolve, reject) => {
        api
          .post(
            "/admin/users",
            {
              email,
              cpf,
              full_name,
              roles,
              contacts,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response: userListResponseNoPagination) => {
            return resolve(response);
          })
          .catch((error: userListResponseNoPagination) => {
            reject(error);
          });
      }),
      {
        pending: "Criando usuário...",
        success: "Usuário criado",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as {
              response: userListResponseNoPagination;
            };

            return data.response.data.errors?.map((error) => error.detail);
          },
        },
      }
    );
  } catch (erro) {
    console.error(erro);
    return false;
  }
};

// Buscar usuário uuid
export const userGetUUID = async (uuid: string) => {
  try {
    return (await toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(`/admin/users/${uuid}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
      {
        pending: "Buscando usuário...",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as {
              response: userListResponseNoPagination;
            };
            return data.response.data.errors?.map((error) => error.detail);
          },
        },
      }
    )) as userListResponseNoPagination;
  } catch (erro) {
    console.error(erro);
    return false;
  }
};

// Filtrar usuário full_name
export const userGetFilterByFullName = async (
  full_name: string,
  pageSize: number,
  pageNumber: number
) => {
  try {
    return (await toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(
            `/admin/users?filter[full_name]=${full_name}&page[size]=${pageSize}&page[number]=${pageNumber}&sort=full_name`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
      {
        pending: "Buscando usuário...",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as {
              response: userListResponseNoPagination;
            };
            return data.response.data.errors?.map((error) => error.detail);
          },
        },
      }
    )) as userListResponse;
  } catch (erro) {
    console.error(erro);
    return false;
  }
};

// atualizar de usuário
export const userPut = async (User: IPostUser, uuid: string) => {
  try {
    const { email, full_name, roles, contacts, cpf } = User;

    return await toast.promise(
      new Promise((resolve, reject) => {
        api
          .put(
            `/admin/users/${uuid}`,
            {
              email,
              cpf,
              full_name,
              roles,
              contacts,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response: userListResponseNoPagination) => {
            return resolve(response);
          })
          .catch((error: userListResponseNoPagination) => {
            reject(error);
          });
      }),
      {
        pending: "Editando usuário...",
        success: "Usuário editado",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as {
              response: userListResponseNoPagination;
            };

            return data.response.data.errors?.map((error) => error.detail);
          },
        },
      }
    );
  } catch (erro) {
    console.error(erro);
    return false;
  }
};

// delete de usuário
export const userDelete = async (uuid: string) => {
  try {
    return await toast.promise(
      new Promise((resolve, reject) => {
        api
          .delete(`/admin/users/${uuid}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
      {
        pending: "Deletando usuário...",
        success: "Usuário deletado",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as {
              response: userListResponseNoPagination;
            };
            return data.response.data.errors?.map((error) => error.detail);
          },
        },
      }
    );
  } catch (erro) {
    console.error(erro);
    return false;
  }
};
