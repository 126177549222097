import PageHeaderUser from "../../../components/Header/Longado";
import ListaProdutos from "../../../components/Produtos/Lista";

function ProdutosList() {
  return (
    <>
      <PageHeaderUser
        ComponenteInterno={<ListaProdutos />}
        title="Lista Produtos"
        description="Lista de Produtos"
      />
    </>
  );
}

export default ProdutosList;
