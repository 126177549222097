import React, { Dispatch } from "react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { PaginationContainerDiv, PaginationNumeroDiv } from "./styles";

interface PaginationProps {
  PageAtual: number;
  PageSize: number;
  SetPageAtual: Dispatch<React.SetStateAction<number>>;
}

const Pagination: React.FC<PaginationProps> = ({
  PageAtual,
  PageSize,
  SetPageAtual,
}) => {
  const inicio = PageAtual >= 1 ? PageAtual : PageAtual - 1;
  const meio = inicio + 1 <= PageSize ? PageAtual + 1 : 0;
  const fim = meio === 0 || meio >= PageSize ? 0 : PageAtual + 2;
  const listaPagination = [inicio, meio, fim];

  return (
    <PaginationContainerDiv>
      {PageAtual > 1 && (
        <PaginationNumeroDiv>
          <MdOutlineKeyboardArrowLeft
            onClick={() => {
              SetPageAtual(PageAtual - 1);
            }}
          />
        </PaginationNumeroDiv>
      )}
      {listaPagination?.map((page) => {
        if (page <= 0 || page > PageSize) {
          return null;
        }
        return (
          <PaginationNumeroDiv
            onClick={() => {
              SetPageAtual(page);
            }}
            key={page}
          >
            {page}
          </PaginationNumeroDiv>
        );
      })}
      {PageAtual < PageSize && (
        <PaginationNumeroDiv>
          <MdOutlineKeyboardArrowRight
            onClick={() => {
              SetPageAtual(PageAtual + 1);
            }}
          />
        </PaginationNumeroDiv>
      )}
    </PaginationContainerDiv>
  );
};

export default Pagination;
