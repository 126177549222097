import { useParams } from "react-router-dom";
import PageHeaderUser from "../../../components/Header/Longado";
import CriarMarcas from "../../../components/Marcas/Criar";

function MarcasCriar() {
  const { uuid } = useParams();
  return (
    <>
      <PageHeaderUser
        ComponenteInterno={<CriarMarcas uuid={uuid ? uuid : ""} />}
        title={`${uuid ? "Editar" : "Criar"} Criar Marcas`}
        description="Lista de Marcas"
      />
    </>
  );
}

export default MarcasCriar;
