import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ModalContext } from "../../../contexts/ModalContext";
import { clienteDelete, clientesGet } from "../../../services/Customer";
import { ICliente } from "../../../utils/interfaces/clientes";
import Pagination from "../../Pagination";
import {
  AllTableList,
  ContainerAll,
  IconFiEdit,
  IconFiSearch,
  IconFiTrash2,
  IconSpace,
  TituloContainerAll,
} from "../../styles.global";

const ListaClientes: React.FC<any> = () => {
  const navigate = useNavigate();
  const { onClientDetails } = useContext(ModalContext);
  const [clientes, setClientes] = useState<ICliente[]>();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageItemSize] = useState(15);
  const [pagesSize, setPagesSize] = useState(0);

  useEffect(() => {
    const consultaClientes = async (
      pageItemSize: number,
      pageNumber: number
    ) => {
      const result = await clientesGet(pageItemSize, pageNumber);
      if (result) {
        setClientes(result.data.data);
        setPagesSize(parseInt(result.data.meta.pagination.pages));
      }
    };

    consultaClientes(pageItemSize, pageNumber);
  }, [pageNumber, pageItemSize]);

  const clienteDeleteUUID = async (client: ICliente) => {
    const result = await Swal.fire({
      title: `Remover Cliente ${client.name}?`,
      text: `Voce tem certeza que deseja excluir ${client.name}? Esta ação não poderá ser desfeita!`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Cancelar",
      denyButtonText: `Remover`,
    });
    if (result.isDenied) {
      await clienteDelete(client.uuid).then((result) => {
        setPageNumber(0);
      });
    }
  };

  return (
    <ContainerAll>
      <TituloContainerAll>
        <div>Todos os clientes</div>
        <div>Botão Filtro</div>
      </TituloContainerAll>
      <AllTableList>
        <table>
          <tbody>
            <tr>
              <th>NOME</th>
              <th>DATA DE CRIAÇÃO</th>
              <th></th>
            </tr>
            {clientes?.map((cliente) => {
              return (
                <tr key={cliente.uuid}>
                  <td>{cliente.name}</td>
                  <td>{cliente.createdAt}</td>
                  <td>
                    <IconSpace>
                      <IconFiSearch
                        onClick={() => onClientDetails(cliente)}
                        size={20}
                      />{" "}
                      <IconFiEdit
                        onClick={() => {
                          return navigate(`/admin/customers/${cliente.uuid}`, {
                            replace: true,
                          });
                        }}
                        size={20}
                      />{" "}
                      <IconFiTrash2
                        onClick={() => clienteDeleteUUID(cliente)}
                        size={20}
                      />
                    </IconSpace>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </AllTableList>
      <Pagination
        PageAtual={pageNumber}
        PageSize={pagesSize}
        SetPageAtual={setPageNumber}
      />
    </ContainerAll>
  );
};

export default ListaClientes;
