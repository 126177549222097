import { useParams } from "react-router-dom";
import CriarClientes from "../../../components/Clientes/Criar";
import PageHeaderUser from "../../../components/Header/Longado";

function ClientesCriar() {
  const { uuid } = useParams();

  return (
    <>
      <PageHeaderUser
        ComponenteInterno={<CriarClientes uuid={uuid ? uuid : ""} />}
        title={`${uuid === "new" ? "Adicionar" : "Editar"} Clientes`}
        description="Lista de Clientes"
        ButtonLink="/admin/customers/new"
      />
    </>
  );
}

export default ClientesCriar;
