import PageHeaderUser from "../../../components/Header/Longado";
import ListaUser from "../../../components/User/Lista";

function UserList() {
  return (
    <>
      <PageHeaderUser
        ComponenteInterno={<ListaUser />}
        title="Lista Usuários"
        description="Lista de Usuários"
        ButtonLink="/admin/users/new"
      />
    </>
  );
}

export default UserList;
