import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { userDelete, userGet } from "../../../services/Users";
import { IUserSimplificado } from "../../../utils/interfaces/user";
import Pagination from "../../Pagination";
import {
  AllTableList,
  ContainerAll,
  IconFiEdit,
  IconFiTrash2,
  IconFiSearch,
  IconSpace,
  TituloContainerAll,
} from "../../styles.global";
import { ModalContext } from "../../../contexts/ModalContext";

const PageUserListar: React.FC<any> = () => {
  const navigate = useNavigate();
  const { onUserDetails } = useContext(ModalContext);
  const [users, setUsers] = useState<IUserSimplificado[]>();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageItemSize] = useState(15);
  const [pagesSize, setPagesSize] = useState(0);

  useEffect(() => {
    const consultaUser = async (pageItemSize: number, pageNumber: number) => {
      const result = await userGet(pageItemSize, pageNumber);
      if (result) {
        setUsers(result.data.data);
        setPagesSize(parseInt(result.data.meta.pagination.pages));
      }
    };

    consultaUser(pageItemSize, pageNumber);
  }, [pageNumber, pageItemSize]);

  const userDeleteUUID = async (user: IUserSimplificado) => {
    const result = await Swal.fire({
      title: `Remover Usuário ${user.profiles.full_name} ?`,
      text: `Voce tem certeza que deseja excluir ${user.profiles.full_name}? Esta ação não poderá ser desfeita!`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Cancelar",
      denyButtonText: `Remover`,
    });
    if (result.isDenied) {
      await userDelete(user.uuid).then((result) => {
        setPageNumber(0);
      });
    }
  };

  return (
    <ContainerAll>
      <TituloContainerAll>
        <div>Todos os usuários</div>
        <div>Botão Filtro</div>
      </TituloContainerAll>
      <AllTableList>
        <table>
          <tbody>
            <tr>
              <th>NOME</th>
              <th>E-MAIL</th>
              <th>DATA DE CRIAÇÃO</th>
              <th></th>
            </tr>
            {users?.map((user) => {
              return (
                <tr key={user.uuid}>
                  <td>{user.profiles.full_name}</td>
                  <td>{user.attributes.email}</td>
                  <td>{user.attributes.createdAt}</td>
                  <td>
                    <IconSpace>
                      <IconFiSearch
                        onClick={() => onUserDetails(user)}
                        size={20}
                      />{" "}
                      <IconFiEdit
                        onClick={() => {
                          return navigate(`/admin/users/${user.uuid}`, {
                            replace: true,
                          });
                        }}
                        size={20}
                      />{" "}
                      <IconFiTrash2
                        onClick={() => userDeleteUUID(user)}
                        size={20}
                      />
                    </IconSpace>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </AllTableList>
      <Pagination
        PageAtual={pageNumber}
        PageSize={pagesSize}
        SetPageAtual={setPageNumber}
      />
    </ContainerAll>
  );
};

export default PageUserListar;
