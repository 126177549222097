import ListaClientes from "../../../components/Clientes/Lista";
import PageHeaderUser from "../../../components/Header/Longado";

function ClientesList() {
  return (
    <>
      <PageHeaderUser
        ComponenteInterno={<ListaClientes />}
        title="Lista Clientes"
        description="Lista de Clientes"
        ButtonLink="/admin/customers/new"
      />
    </>
  );
}

export default ClientesList;
