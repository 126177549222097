import { styled } from "@mui/material/styles";

export const PaginationContainerDiv = styled("div")`
  color: black;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  padding: 15px 24px;
  border-top: 1px solid #e9ecef;
`;

export const PaginationNumeroDiv = styled("div")`
  background: #00e693;
  box-shadow: 0px 7px 14px rgba(50, 50, 93, 0.1),
    0px 3px 6px rgba(0, 0, 0, 0.08);

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;

  width: 35px;
  height: 35px;
  color: #0d0d0d;
  border: 1px solid #e9ecef;
  border-radius: 50%;
  justify-content: center;
  margin-right: 7px;

  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
`;
