import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PrivateRoutes, TokenRoutes } from "./components/Auth/isAuthenticated";
import LoginPage from "./pages/Auth/Login";
import Remember from "./pages/Auth/Remember";
import ClientesCriar from "./pages/Clientes/Criar";
import ClientesList from "./pages/Clientes/Lista";
import MarcasCriar from "./pages/Marcas/Criar";
import MarcasList from "./pages/Marcas/Lista";
import ProdutosCriar from "./pages/Produtos/Criar";
import ProdutosList from "./pages/Produtos/Lista";
import UserCriar from "./pages/User/Criar";
import UserList from "./pages/User/Lista";

function Router() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/newpassword/:code" element={<Remember />} />
          <Route element={<TokenRoutes />}>
            <Route path="*" element={<LoginPage />} />
          </Route>
          <Route path="/login" element={<LoginPage />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/admin/users" element={<UserList />} />
            <Route path="/admin/users/:uuid" element={<UserCriar />} />
            <Route path="/admin/products" element={<ProdutosList />} />
            <Route path="/admin/products/:uuid" element={<ProdutosCriar />} />
            <Route path="/admin/brands" element={<MarcasList />} />
            <Route path="/admin/brands/:uuid" element={<MarcasCriar />} />
            <Route path="/admin/customers" element={<ClientesList />} />
            <Route path="/admin/customers/:uuid" element={<ClientesCriar />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default Router;
