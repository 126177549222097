import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { MdOutlineAdd } from "react-icons/md";
import { userGetFilterByFullName } from "../../../services/Users";
import { IUserSimplificado } from "../../../utils/interfaces/user";
import {
  UsersFilteredList,
  UsersFilteredListItem,
  UsersSection,
} from "./styles";

interface IProps {
  setSelectedUsers: (user: IUserSimplificado) => void;
  selectedUsers: IUserSimplificado[];
}

const UserFilteredInput: React.FC<IProps> = ({
  setSelectedUsers,
  selectedUsers,
}) => {
  const [users, setUsers] = useState<IUserSimplificado[]>([]);
  const [filteredInput, setFilteredInput] = useState<string>("");

  useEffect(() => {
    if (filteredInput.length > 0) {
      userGetFilterByFullName(filteredInput, 1, 1)
        .then((res) => {
          if (res !== false) {
            userGetFilterByFullName(
              filteredInput,
              Number(res.data.meta.pagination.items),
              1
            )
              .then((res) => {
                console.log(res);
                if (res !== false && filteredInput.length > 0) {
                  setUsers(res.data.data);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setUsers([]);
    }
  }, [filteredInput]);

  const handleAddUser = (user: IUserSimplificado) => {
    setSelectedUsers(user);
  };

  return (
    <UsersSection>
      <input
        type="text"
        placeholder="Pesquisar usuário..."
        value={filteredInput}
        onChange={(e) => setFilteredInput(e.target.value)}
      />

      <UsersFilteredList>
        {users.map((user) => {
          const added = selectedUsers.find((u) => u.uuid === user.uuid);
          return (
            <UsersFilteredListItem key={user.uuid}>
              <span>{user.profiles.full_name}</span>
              <button
                type="button"
                className={added ? "added" : ""}
                onClick={() => handleAddUser(user)}
              >
                {added ? <FaCheck /> : <MdOutlineAdd />}
              </button>
            </UsersFilteredListItem>
          );
        })}
      </UsersFilteredList>
    </UsersSection>
  );
};

export default UserFilteredInput;
