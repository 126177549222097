export const cpfMask = (value: string | undefined) => {
  if (value !== undefined) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  }
  return value;
};

export const cnpjMask = (value: string | undefined) => {
  if (value !== undefined) {
    return value
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }
  return value;
};

export const phoneMask = (value: string | undefined) => {
  if (value !== undefined) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d)(\d{4})$/, "$1-$2");
  }
  return value;
};

export const emailRegex = /^[a-z0-9.]{3,}@[a-z0-9]+\.[a-z]+\.*([a-z]+)?$/gi;
export const phoneRegex = /\d{10,11}/g;
