import React from "react";

import { ProdutosDiv } from "./styles";

const PageProdutos: React.FC<any> = () => {
  return (
    <ProdutosDiv>
      <div>Pagina Criar Produtos</div>
    </ProdutosDiv>
  );
};

export default PageProdutos;
