import { styled } from "@mui/material/styles";

export const PageHeaderDiv = styled("div")`
  display: flex;
  flex-direction: column;
  background-color: #000000;
`;

export const TopBarContainerDiv = styled("div")`
  margin: 125px auto;
`;
