import { styled } from "@mui/material/styles";

export const UserDiv = styled("form")`
  color: #1100ff;
  padding: 24px;
  overflow-y: auto;
  height: 100%;
`;

export const UserDivTitle = styled("div")`
  color: #525f7f;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 44px;
`;

export const Row = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 36px;
  padding: 0 75px;

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 0;
  }
`;

export const FormControl = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 28px;

  label {
    font-size: 16px;
    font-weight: 600;
    color: #002554;
    margin-bottom: 9px;
  }

  input {
    width: 100%;
    font-size: 16px;
    font-weight: 400;

    background-color: #f6f9fc;
    border: none;
    padding: 14px 12px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.02),
      inset 0px 1px 3px rgba(50, 50, 93, 0.15);
    border-radius: 6px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: inset 1px 1px 2px rgba(50, 50, 93, 0.15) !important;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 800px) {
    margin-bottom: 36px;
  }
`;

export const FormInline = styled("div")`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-right: 28px;

  input {
    margin-right: 25px;
  }

  button {
    color: #0d0d0d;
    font-size: 16px;
    font-weight: 600;
    padding: 4px 13px 0 13px;
    background-color: #00e693;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08),
      0px 4px 6px rgba(50, 50, 93, 0.1);
    border-radius: 6px;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #00da8c;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const ButtonContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  padding: 0 75px;

  button {
    color: #0d0d0d;
    font-size: 16px;
    font-weight: 600;
    padding: 13px 40px;
    background-color: #00e693;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08),
      0px 4px 6px rgba(50, 50, 93, 0.1);
    border-radius: 6px;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #00da8c;
    }
  }

  @media (max-width: 800px) {
    padding: 0 20px;
  }
`;

export const DivTable = styled("div")`
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  border-radius: 6px;
  table {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: #002554;
  }

  th {
    text-align: start;
    padding: 15px;
  }

  td {
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    &:last-child {
      text-align: center;
    }
  }

  tr {
    background-color: #ffffff;
    border-radius: 6px;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #e84343;

    &:hover {
      color: red;
    }
  }
`;

export const TdData = styled("div")`
  display: flex;
  flex-direction: row;

  span {
    margin-right: 20px;
  }
`;

export const Column = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f6f6f6;
  margin: 0 75px;
  gap: 10px;
  border-radius: 6px;
  margin-bottom: 20px;

  @media (max-width: 800px) {
    margin: 0 20px;
  }
`;

export const UserInput = styled("input")`
  @media (max-width: 800px) {
    margin-bottom: 10px;
  }
`;

export const UserSubmit = styled("button")`
  @media (max-width: 800px) {
    padding: 10px !important;
  }
`;

export const SelectContainer = styled("div")`
  position: relative;
  display: inline;
  background-color: #f6f9fc;
  border: none;
  padding: 14px 12px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.02),
    inset 0px 1px 3px rgba(50, 50, 93, 0.15);
  border-radius: 6px;
  margin-right: 25px;

  &.no-margin {
    margin: 0px;
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    pointer-events: none;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: calc(50% - 4px);
    right: 0.75em;
    border-top: 8px solid black;
    opacity: 0.5;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 16px;
    font-weight: 400;
    background-color: transparent;
    outline: none;
    border: none;
    padding-right: 25px;
    width: 100%;

    &.contact-type {
      width: 145px;
    }
  }

  select::-ms-expand {
    display: none;
  }

  @media (max-width: 800px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;
