import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../../components/Header/Auth";
import logoMtech from "../../../images/logo-mtech.svg";
import {
  sendLoginRememberGet,
  sendLoginRememberPost,
} from "../../../services/Auth";
import {
  LoginButton,
  LoginContent,
  LoginContentButton,
  LoginDiv,
  LoginFormularioDiv,
  LoginH3,
  LoginH4,
  LoginInput,
  LoginInputError,
  LoginLogoContent,
  LoginTituloDiv,
} from "../stylesAuth";

function Remember() {
  const navigate = useNavigate();
  const { code } = useParams();
  const [password, setPassword] = useState<string>("");
  const [passwordRepeat, setPasswordRepeat] = useState<string>("");

  const loginRememberPost = async (e: FormEvent) => {
    e.preventDefault();
    if (code) {
      if (await sendLoginRememberPost(code, password)) {
        return navigate("/login", { replace: true });
      }
    }
  };

  useEffect(() => {
    if (code !== undefined) {
      sendLoginRememberGet(code)
        .then((result) => {
          if (!result) {
            return navigate("/login", { replace: true });
          }
        })
        .catch((err) => {
          return navigate("/login", { replace: true });
        });
    } else {
      const auth = { token: localStorage.getItem("token") };
      if (auth) {
        return navigate("/admin/users", { replace: true });
      }
    }
  }, [code, navigate]);

  return (
    <LoginContent>
      <LoginLogoContent>
        <img src={logoMtech} alt="Login" />
      </LoginLogoContent>
      <PageHeader title="Nova Senha" />
      <LoginDiv>
        <>
          <LoginTituloDiv>
            <LoginH4>Preencha os dados abaixo para alterar sua senha</LoginH4>
          </LoginTituloDiv>
          <LoginFormularioDiv onSubmit={loginRememberPost}>
            <LoginH3>Senha</LoginH3>
            <LoginInput
              autoComplete="off"
              onChange={(e) => setPassword(e.target.value)}
              title="password"
              type="password"
              name="password"
              id="password"
            ></LoginInput>
            <br />
            <br />
            <LoginH3>Confirme a senha</LoginH3>
            <LoginInput
              autoComplete="off"
              onChange={(e) => setPasswordRepeat(e.target.value)}
              title="passwordRepeat"
              type="password"
              name="passwordRepeat"
              id="passwordRepeat"
            ></LoginInput>
            <br />
            <br />

            {passwordRepeat === "" && password !== "" && (
              <LoginInputError>Repita sua senha</LoginInputError>
            )}

            {password !== passwordRepeat && passwordRepeat !== "" && (
              <LoginInputError>Senha diferente</LoginInputError>
            )}

            {password === passwordRepeat && passwordRepeat !== "" && (
              <LoginContentButton>
                <LoginButton title="Enviar">Enviar</LoginButton>
              </LoginContentButton>
            )}
          </LoginFormularioDiv>
        </>
      </LoginDiv>
    </LoginContent>
  );
}

export default Remember;
