import PageHeaderUser from "../../../components/Header/Longado";
import ListaMarcas from "../../../components/Marcas/Lista";

function MarcasList() {
  return (
    <>
      <PageHeaderUser
        ComponenteInterno={<ListaMarcas />}
        title="Lista Marcas"
        description="Lista de Marcas"
      />
    </>
  );
}

export default MarcasList;
