import { toast } from "react-toastify";
import { Logout } from "../components/Auth/isAuthenticated";
import api from "./api";
import { dadosUser } from "./Users";

export const sendLogin = async (username: string, password: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .post("/admin/login_check", {
            username: username,
            password: password,
          })
          .then((response) => {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("refresh_token", response.data.refresh_token);
            return resolve(
              new Promise(async (resolve, reject) => {
                await dadosUser(response.data.token)
                  .then(() => {
                    return resolve(true);
                  })
                  .catch(() => {
                    return reject(false);
                  });
              })
            );
          })
          .catch((error) => {
            return reject(false);
          });
      }),
      {
        error: "Usuário ou senha Invalido",
      }
    );
  } catch (erro) {
    console.error(erro);
    Logout();
    return false;
  }
};

export const sendForgotLogin = async (username: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .post("/admin/user/remember_password", {
            email: username,
          })
          .then((response) => {
            localStorage.setItem("token", response.data.token);
            return resolve(true);
          })
          .catch((error) => {
            return reject(false);
          });
      }),
      {
        pending: "Enviando E-mail...",
        success: "E-mail Enviado",
        error: "Erro ao enviar E-mail",
      }
    );
  } catch (erro) {
    console.error(erro);
  }
};

export const sendLoginRememberGet = async (code: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(`/admin/user/remember_password/${code}`)
          .then((response) => {
            return resolve(true);
          })
          .catch((error) => {
            return reject(false);
          });
      }),
      {
        pending: "Verificando Token...",
        success: "Token Valido",
        error: "Token Invalido",
      }
    );
  } catch (erro) {
    console.error(erro);
    return false;
  }
};

export const sendLoginRememberPost = async (code: string, password: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .put(`/admin/user/remember_password/${code}`, {
            password: password,
          })
          .then((response) => {
            return resolve(true);
          })
          .catch((error) => {
            return reject(false);
          });
      }),
      {
        pending: "Atualizando senha...",
        success: "Senha modificada",
        error: "Erro ao alterar senha",
      }
    );
  } catch (erro) {
    toast.error("Erro ao trocar senha");
    return false;
  }
};

export const sendLoginRefreshPos = async () => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .post(`/admin/token/refresh`, {
            refresh_token: `${localStorage.getItem("refresh_token")}`,
          })
          .then((response) => {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("refresh_token", response.data.refresh_token);
            return resolve(true);
          })
          .catch((error) => {
            return reject(false);
          });
      }),
      {
        error: "Token revogado",
      }
    );
  } catch (erro) {
    console.error(erro);
    return false;
  }
};
