import { styled } from "@mui/material/styles";

export const LoginContent = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to top,
    #f2f2f2 0%,
    #f2f2f2 55%,
    black 55%,
    black 100%
  );
  height: 100vh;
  padding: 0;

  @media (max-width: 700px) {
    padding: 0 10px;
  }
`;

export const LoginDiv = styled("div")`
  background-color: white;
  border-radius: 6px;
  height: 500px;
  width: 100%;
  max-width: 541px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-top: 80px;
  position: relative;
`;

export const LoginTituloDiv = styled("div")`
  height: 90px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 1px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 700px) {
    padding: 0 20px;
  }
`;

export const LoginH3 = styled("h3")`
  color: #0d0d0d;
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 9px;
`;
export const LoginH4 = styled("h4")`
  color: #535353;
  font-weight: 500;
  font-size: 18px;
`;
export const LoginH5 = styled("h5")`
  color: #535353;
`;

export const LoginFormularioDiv = styled("form")`
  align-items: center;
  padding: 37px;

  @media (max-width: 700px) {
    padding: 37px 20px;
  }
`;

export const LoginInput = styled("input")`
  height: 45px;
  width: 100%;
  background: #f6f9fc;
  border: none;
  box-shadow: inset 1px 1px 2px rgba(50, 50, 93, 0.15);
  border-radius: 6px;
  padding: 0 20px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: inset 1px 1px 2px rgba(50, 50, 93, 0.15) !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const LoginContentButton = styled("div")`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

export const LoginButton = styled("button")`
  border: none;
  margin-top: 30px;
  padding: 15px 60px;
  gap: 15px;

  background-color: #00e693;
  color: #000000;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 4px 6px 0px,
    rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  border-radius: 6px;

  cursor: pointer;

  &:hover {
    background-color: #00da8c;
  }
`;

export const LoginContentA = styled("div")`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
`;

export const LoginA = styled("a")`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  text-align: center;
  text-decoration-line: underline;

  color: #00e693;
  cursor: pointer;

  &:hover {
    color: #00da8c;
  }
`;

export const FiArrowLeftContent = styled("div")`
  position: absolute;
  width: 24px;
  height: 24px;
  left: 29px;
  top: 39px;
  cursor: pointer;
  color: #535353;
`;

export const LoginLogoContent = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 700px) {
    padding-top: 20px;
  }

  @media (max-width: 400px) {
    img {
      width: 200px;
    }
    padding-top: 20px;
  }
`;

export const LoginInputError = styled("span")`
  color: red;
`;
