import { styled } from "@mui/material/styles";
import { BsFillPlayFill } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";

export const SidebarHeaderContainer = styled("div")`
  display: flex;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    to bottom,
    black 0%,
    black 247px,
    #f2f2f2 247px,
    #f2f2f2 100%
  );
`;

export const Sidebar = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  min-width: 230px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15);
  position: relative;

  @media (max-width: 800px) {
    z-index: 2;
    position: absolute;
    left: -300px;

    &.opened {
      left: 0;
      transition: ease 0.4s;
    }
  }
`;

export const MenuContainerSection = styled("div")`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border: none;
  }
`;

export const MenuContainer = styled("div")`
  padding: 0 20px;
`;

export const MenuContainerHeader = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 14px 0;

  span {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 16px;
    margin-left: 15px;
  }
`;

export const MenuContainerItem = styled("div")`
  cursor: pointer;
  display: flex;

  margin-left: 36px;
  margin-bottom: 10px;
  align-items: center;

  span {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin-left: 4px;
  }

  &:last-child {
    margin-bottom: 24px;
  }

  &:hover {
    color: #00e693;
  }

  &.active {
    color: #00e693;
  }

  &.not-expanded {
    margin-left: 5px;
  }
`;

export const HeaderContent = styled("div")`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const DetalhesUser = styled("div")`
  color: #ffffff;
  min-width: 128px;
  max-width: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const DetalhesContainer = styled("div")`
  color: #fff;
  height: 100%;
`;
export const HeaderComponent = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 30px;
  padding-bottom: 0;

  h1 {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 21px;
  }
`;

export const UserSpan = styled("span")`
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
`;

export const UserAvatar = styled("div")`
  width: 32px;
  border-radius: 50%;
  /* margin-right: 10px; */
  margin-left: 10px;
`;

export const LogoMenu = styled("img")`
  height: 50px;
`;

export const HeaderSearch = styled("input")`
  border-radius: 50px;
  padding: 9px 22px;
  outline: none;
  border: none;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #535353;
  margin: 0 20px;
`;

export const Header = styled("div")`
  display: flex;
  justify-content: space-between;
  height: 80px;
  border: solid;
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 1px;
  border-color: rgba(255, 255, 255, 0.22);
  align-items: center;
  padding: 22px 30px;
`;

export const SidebarMenu = styled("div")``;

export const AlignCenter = styled("div")`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

export const BsFillPlayFillStyled = styled(BsFillPlayFill)``;

export const CopyrightContainer = styled("div")`
  padding: 10px 20px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;

  a {
    text-decoration: none;
    color: #474a57;
  }
`;

export const ComponentContent = styled("div")`
  background-color: white;
  border-radius: 6px;
  height: 100%;
  margin: 25px 30px;
  overflow: hidden;
`;

export const IconFiLogOut = styled(FiLogOut)`
  :hover {
    color: #00e693;
    cursor: pointer;
  }
`;

export const PageHeaderButtonDiv = styled("div")`
  background: #00e693;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(50, 50, 93, 0.1);
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0d0d0d;
  width: 160px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #2fc48d;
    cursor: pointer;
  }
`;

export const MenuButton = styled("button")`
  display: none;
  cursor: pointer;
  background-color: transparent;
  border: none;

  @media (max-width: 800px) {
    display: flex;
  }
`;

export const HeaderActions = styled("div")`
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const Backdrop = styled("div")`
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  z-index: 1;

  @media (max-width: 800px) {
    &.opened {
      display: flex;
      position: absolute;
      left: 0;
    }
  }
`;

export const CloseSidebar = styled("div")`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  cursor: pointer;

  @media (max-width: 800px) {
    display: flex;
  }

  &:hover {
    color: #00e693;
  }
`;

export const ExpandedButton = styled("div")`
  position: absolute;
  top: 27px;
  right: -13px;
  background-color: #ffffff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
