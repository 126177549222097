import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { userGet } from "../../../services/Users";
import { IUserSimplificado } from "../../../utils/interfaces/user";
import Pagination from "../../Pagination";
import {
  AllTableList,
  ContainerAll,
  IconFiEdit,
  IconFiTrash2,
  IconSpace,
  TituloContainerAll,
} from "../../styles.global";

const PageMarcasListar: React.FC<any> = () => {
  // ! TDO: criar interface da marca
  const [users, setUsers] = useState<IUserSimplificado[]>();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageItemSize] = useState(5);
  const [pagesSize, setPagesSize] = useState(0);

  const consultaUser = (pageItemSize: number, pageNumber: number) => {
    const token = localStorage.getItem("token");
    if (token) {
      return userGet(pageItemSize, pageNumber)
        .then((result) => {
          if (result) {
            setUsers(result.data.data);
            setPagesSize(parseInt(result.data.meta.pagination.pages));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    consultaUser(pageItemSize, pageNumber);
  }, [pageNumber, pageItemSize]);

  return (
    <ContainerAll>
      <TituloContainerAll>
        <div>Todas as Marcas</div>
        <div>Botão Filtro</div>
      </TituloContainerAll>
      <AllTableList>
        <table>
          <tbody>
            <tr>
              <th>Marca</th>
              <th>Autor</th>
              <th>DATA DE CRIAÇÃO</th>
              <th></th>
            </tr>
            {users?.map((user) => {
              return (
                <tr key={user.uuid}>
                  <td>{user.profiles.full_name}</td>
                  <td>{user.attributes.email}</td>
                  <td>{user.attributes.createdAt}</td>
                  <td>
                    <IconSpace>
                      <IconFiEdit
                        onClick={() => {
                          toast.warning(`Atualizar id: ${user.uuid}`);
                        }}
                        size={20}
                      />{" "}
                      <IconFiTrash2
                        onClick={() => {
                          toast.warning(`Deletar id: ${user.uuid}`);
                        }}
                        size={20}
                      />
                    </IconSpace>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </AllTableList>
      <Pagination
        PageAtual={pageNumber}
        PageSize={pagesSize}
        SetPageAtual={setPageNumber}
      />
    </ContainerAll>
  );
};

export default PageMarcasListar;
